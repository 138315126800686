@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Krona+One&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*:focus {
  outline: none;
}

.audiowide {
  font-family: 'Audiowide', cursive !important;
}

.clickable {
  cursor: pointer;
}

.clickable:hover {
  color: #73767E;
}

.page {
  background: linear-gradient(90deg, #808a8c 0%, #4b565c 100%);
  color: #FFF;
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.written {
  font-family: 'Krona one', cursive !important;
  text-transform: none !important;
  font-weight: 900 !important;
}

.radius-0 {
  border-radius: 0 !important;
}

.scale_max {
  scale: 1.15;
}

h1 {
  font-family: 'Poppins', sans-serif !important;
  display: block;
  margin: 100px auto 10px auto;
  font-weight: 800;
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  max-width: 960px;
  padding: 0 20px;
  font-weight: 700;
}

h4 {
  display: block;
  font-weight: 500;
  font-size: 16px;
  text-align: center !important;
  color: #EEE;
  margin: 0 auto 30px auto;
  max-width: 960px;
  padding: 0 20px;
}

h5 {
  font-size: 20px;
  font-weight: 800;
  margin: 25px 0 0 0;
}

.web_f-right {
  float: right;
}

.label {
  display: block;
  margin: 14px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: #EEE;
  position: relative;
}

.fontawesome svg {
  position: absolute;
  left: -5px;
  top: 2px;
}

.tag {
  display: block;
  margin: 14px 0 0 0;
  font-size: 11px;
  font-weight: 600;
  color: #000;
  padding: 4px 10px;
  border-radius: 20px;
  background-color: #FFBB00;
  width: fit-content;
}

.tag_off {
  display: block;
  margin: 14px 0 0 0;
  font-size: 11px;
  font-weight: 600;
  color: #000;
  padding: 4px 10px;
  border-radius: 20px;
  background-color: #c6cdd9;
  width: fit-content;
}

.logout {
  display: block;
  margin: 14px 0 0 0;
  font-size: 14px;
  font-weight: 500;
  color: #DDD;
  text-decoration: underline;
  cursor: pointer;
}

.next_lvl {
  display: block;
  margin: 10px 0 0 0;
  font-size: 12px;
  font-weight: 500;
  color: #CCC;
  text-align: right;
}

.min_notice {
  display: block;
  margin: 8px 0 0 0;
  font-size: 11px;
  font-weight: 400;
  color: #EEE;
  text-align: left;
}

.vs_fighters {
  display: block;
  margin: 5px 0 0 0;
  font-size: 13px;
  font-weight: 500;
  color: #FFF;
  text-align: left;
}

.value {
  display: block;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #FFF;
  text-transform: capitalize;
  position: relative;
}

.about {
  display: block;
  margin: 0;
  font-size: 16px;
  font-weight: 700;
  color: #000;
}

.huge {
  font-size: 3vw !important;
  font-weight: 800 !important;
}

.opensea {
  display: block;
  margin: 20px auto 0px auto;
  width: 160px;
  cursor: pointer;
}

.d-block {
  display: block !important;
  margin: 10px auto !important;
}

.d-center {
  display: block;
  margin: 0 auto !important;
}

.d-none {
  display: none !important;
}

.v-hidden {
  visibility: hidden !important;
}

.align-right {
  display: block;
  margin: 0 auto;
  margin-top: -210px ;
  height: 200px;
  width: 50%;
  object-position: 50% 0;
  object-fit: cover;
}

.align-left {
  display: block;
  margin: 0 auto;
  margin-top: -210px ;
  height: 200px;
  width: 50%;
  object-position: 50% 0;
  object-fit: cover;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.responsive-text-right {
  text-align: right !important;
}

.responsive-m-left {
  margin-left: 20px !important;
}

.responsive-m-right {
  margin-right: 20px !important;
}

.d-inline-block {
  display: inline-block !important;
  vertical-align: top !important;
}

.light-grey {
  color: #CACED6
}

.grey {
  color: #9ea1a7 !important;
}

.black {
  color: #000 !important;
}

.white {
  color: #FFF !important;
}

.danger {
  color: #ff4e4e !important;
}

.primary {
  color: #FFBB00 !important;
}

.coming {
  color: #00FF00 !important;
}

.back-primary {
  background-color: #FFBB00 !important;
}

.back-coming {
  background-color: #00FF00 !important;
}

.back-premium {
  background-color: #ed4eff !important;
}

.back-common {
  background-color: #4bb4ff !important;
}

.back-danger {
  background-color: #ff4e4e !important;
}

.back-black {
  background-color: #000 !important;
}

.back-closed {
  background-color: #AAA !important;
}

.back-dark {
  background-color: #555 !important;
}

.back-fulldark {
  background-color: #333 !important;
}

.back-white {
  background-color: #FFF !important;
}

.green {
  color: #00FF00 !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.transform-none {
  text-transform: none !important;
}

.starter {
  margin-top: 180px !important;
}

.fit-content {
  width: fit-content !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.p10 {
  padding: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.mb-150 {
  margin-bottom: 150px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.ft-11 {
  font-size: 11px !important;
}

.ft-14 {
  font-size: 14px !important;
}

textarea {
  width: 280px !important;
  height: 180px !important;
  border-radius: 10px;
  border: 1px solid #FFF;
  font-family: 'Poppins', sans-serif;
  padding: 6px 12px;
  background-color: rgba(0, 0, 0, .25);
  color: #FFF;
  resize: none;
}

.min-ft-14 {
  font-size: 14px !important;
}

.post-ft-14 {
  font-size: 15px !important;
  font-weight: 400 !important;
}

.comment-ft-14 {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.safe_holder {
  display: block;
  margin: 20px auto;
  max-width: 800px;
  padding: 0 20px;
}

.safe_holder p {
  font-size: 14px;
}

.padding {
  padding: 10px 20px;
}

.padding-safe {
  padding: 0 20px;
}

.pointer {
  cursor: pointer !important;
}

.disabled {
  background-color: #111 !important;
  cursor: auto !important;
  color: #AAA !important;
}

.disabled:hover {
  background-color: #111 !important;
}

.notice_board {
  border: 1px solid #EEE;
  padding: 0 10px 10px 10px;
  border-radius: 10px;
  display: block;
  margin: 10px auto;
}

.rank_tag {
  display: inline-block;
  padding: 0 10px;
  width: fit-content;
  height: 22px;
  border-radius: 5px;
  background: rgb(103, 103, 119);
  background: linear-gradient(117deg, rgba(103, 103, 119, 1) 0%, rgba(2, 2, 43, 1) 100%);
  text-align: center;
  line-height: 22px;
  color: #FFF;
  font-size: 15px;
  vertical-align: middle;
}

.winner_tag {
  display: inline-block;
  vertical-align: top;
  height: 20px;
  line-height: 20px;
  padding: 0 12px;
  border-radius: 10px;
  background-color: #00FF00;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  margin: -1px 5px;
}

.undefeated {
  display: inline-block;
  vertical-align: top;
  height: 20px;
  line-height: 20px;
  padding: 0 8px;
  border-radius: 10px;
  background-color: #FFBB00;
  color: #000;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  margin: 5px;
}

.awaiting_score {
  display: block;
  margin: 10px -26px;
  background: linear-gradient(90deg, #dba100 0%, #FFBB00 100%);
  padding: 0 40px 0 28px;
  text-align: left;
  color: #000;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-weight: 600;
  font-size: 18px;
  height: 38px;
  line-height: 38px;
  width: fit-content;
}

.forgotten {
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  text-decoration: underline;
}

.forgotten a {
  color: #00FF00 !important;
}

.cancel {
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 8px;
  border: 2px solid #FFF;
  border-radius: 20px;
  padding: 5px 14px;
  display: block;
  width: fit-content;
  margin-bottom: 15px;
}

.profile_action {
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  border: 2px solid #FFF;
  border-radius: 20px;
  padding: 5px 14px;
  display: block;
  width: fit-content;
  margin: 4px auto 8px auto;
}

.profile_action:hover {
  border: 2px solid #00FF00;
  color: #00FF00;
}

.right_padding {
  padding-right: 35px;
  position: relative;
  height: 20px;
}

.right_padding svg {
  position: absolute;
  top: 7px;
  right: 12px;
}

.padding-left-none {
  padding-left: 0;
}

.card-select {
  display: inline-block !important;
  margin: 25px !important;
}

.card-select:hover {
  border: 5px solid #FFBB00 !important;
  margin: 20px !important;
  cursor: pointer;
}

.action {
  cursor: pointer;
}

.action:hover {
  background-color: #00FF00 !important;
  cursor: pointer;
}

.action_selected {
  background-color: #00FF00 !important;
}

.card-confirm {
  display: inline-block !important;
  box-shadow: none !important;
  background: rgba(0, 0, 0, 0) !important;
}

.left-card {
  position: absolute;
  z-index: -1;
  left: -200px;
  top: 80px;
  width: 300px;
  border-radius: 15px;
}

.right-card {
  position: absolute;
  z-index: -1;
  right: -200px;
  top: 80px;
  width: 300px;
  border-radius: 15px;
}

.h-auto {
  min-height: auto !important;
  height: auto !important;
  padding-bottom: 20px !important;
}

.banner_goat {
  height: 600px;
  width: 960px;
  margin: 160px auto 0 auto;
  display: block;
  position: relative;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.banner_goat .blurb {
  position: absolute;
  right: 20px;
  top: 0;
  width: 390px;
  text-align: left;
  color: #FFF;
}

.banner_goat .simple_text {
  font-size: 14px;
  font-weight: 500;
  margin: 20px 10px;
  color: #FFF;
}

.banner_goat .blurb h1 {
  font-weight: 800;
  font-size: 34px;
  color: #FFF;
}

.signup {
  margin: 20px 20px 0 0;
  border-radius: 25px;
  padding: 0 18px 0 40px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background-color: #FFF;
  color: #000;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  font-size: 16px;
  position: relative;
}

.signup:hover {
  color: #FFF;
}

.signup svg {
  position: absolute;
  left: 15px;
  top: 8px;
}

.border_banner {
  height: 1px;
  width: 100%;
  background-color: #868f90;
}

.aura {
  position: relative;
  width: fit-content;
  display: block;
  margin: 10px auto 120px auto;
  padding: 4px;
  border: 6px solid #000;
  border-radius: 40px;
}

.aura:hover {
  border: 6px solid #222;
}

.launch {
  margin: 0;
  border-radius: 25px;
  padding: 0 18px 0 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #000;
  color: #FFF;
  font-weight: 600;
  display: block;
  cursor: pointer;
  font-size: 20px;
  position: relative;
  width: fit-content;
}

.launch:hover {
  background-color: #222;
}

.launch_white {
  background-color: #FFF !important;
  color: #000 !important;
}

.launch_white:hover {
  background-color: #FAFAFA !important;
  color: #000 !important;
}

.aura:hover .launch {
  background-color: #222;
}

.launch .sha {
  position: absolute;
  left: 6px;
  top: 2px;
  width: 30px;
}

.launch_white .sha {
  position: absolute;
  left: 6px;
  top: 2px;
  width: 30px;
}

.banner_goat .download {
  margin: 30px 20px 0 0;
  height: 34px;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.profile_news {
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  border-radius: 10px;
  width: fit-content;
  padding: 4px;
  margin-left: 25px;
  margin-bottom: -2px !important;
  z-index: 2;
}

.profile_news h4 {
  color: #222;
  font-size: 15px;
}

.avatar_profile {
  width: 50px;
  height: 50px;
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  text-align: left;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
}

.big_profile {
  display: block;
  margin: 160px auto 10px auto;
  background: #444d5f;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
  border-radius: 25px;
  width: 800px;
  height: 140px;
  padding: 20px;
}

.big_profile .avatar {
  width: 140px;
  height: 140px;
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  text-align: center;
  display: inline-block;
  vertical-align: top;
  border-radius: 20px;
  margin-right: 25px;
  background-position: center center;
  background-size: cover;
}

.max_holder {
  display: block;
  margin: 20px auto;
  text-align: center;
}

.max_controller {
  width: 400px;
}

.minimizer {
  max-width: 500px !important;
  margin-left: calc(50% - 150px) !important;
}

.min_holder {
  width: fit-content !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.max_holder .card {
  display: block;
  margin: 20px auto;
  width: 360px;
  height: 540px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  background: linear-gradient(-30deg, rgba(103, 103, 119, .2) 0%, rgba(2, 2, 43, .2) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.max_holder .center_top {
  background-position: center top;
}

.max_holder .card_event {
  display: block;
  margin: 20px auto;
  width: 360px;
  height: 540px;
  border-radius: 20px;
  background-color: #E5E8EE;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.max_holder .card2 {
  width: 320px;
  height: 480px;
  border-radius: 20px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: -200px;
  top: 30px;
  z-index: -1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.max_holder .card3 {
  width: 287px;
  height: 430px;
  border-radius: 20px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: -380px;
  top: 60px;
  z-index: -2;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.max_holder .card4 {
  width: 260px;
  height: 390px;
  border-radius: 20px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  right: -540px;
  top: 82px;
  z-index: -3;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.max_holder .card .actionbox {
  position: absolute;
  top: 12px;
  right: 8px;
  border-radius: 8px;
  padding: 4px;
}

.max_holder .card .actionbox .export {
  width: 30px;
  height: 30px;
  line-height: 35px;
  background: rgba(12, 14, 18, .3);
  text-align: center;
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  cursor: pointer;
  animation: move 1s infinite alternate;
  display: inline-block;
  vertical-align: top;
  margin-right: 8px;
}

.max_holder .card .info {
  width: 240px;
  height: 435px;
  border-radius: 20px;
  background: #444d5f;
  background: linear-gradient(-20deg, #444d5f 0%, #0c0e12 100%) !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  left: -250px;
  top: 60px;
  padding: 0 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.max_holder .card .info .profile {
  padding: 5px;
  display: block;
  margin: 30px auto 0 auto;
  width: calc(100% + 15px);
  background-color: rgba(0, 0, 0, .7);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  min-height: 40px;
  cursor: pointer;
}

.nb_one {
  background: rgb(255, 217, 0) !important;
  background: linear-gradient(140deg, rgb(255, 217, 0) 0%, rgb(255, 196, 0) 100%) !important;
  color: #000 !important;
}

.nb_two {
  background: rgb(229, 229, 229) !important;
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%) !important;
  color: #000 !important;
}

.nb_three {
  background: rgb(251, 159, 0) !important;
  background: linear-gradient(140deg, rgb(251, 159, 0) 0%, rgb(212, 120, 0) 100%) !important;
  color: #000 !important;
}

.nb_general {
  width: 42px;
  height: 42px;
  background: rgb(83, 83, 83);
  background: linear-gradient(140deg, rgb(83, 83, 83) 0%, rgb(20, 20, 20) 100%);
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  color: #FFF;
  line-height: 42px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
}

.max_holder .card .info .profile .avatar {
  width: 42px;
  height: 42px;
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  color: #000;
  line-height: 42px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  margin-left: 5px;
  background-position: center center;
  background-size: cover;
}

.max_holder .card .info .profile .info_profile {
  width: calc(100% - 114px);
  height: 42px;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
  font-weight: 600;
  color: #FFF;
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;
}

.max_holder .card .longer {
  height: 500px;
  top: 20px;
}

.max_holder .card .info_right {
  left: 380px;
  border-radius: 20px;
  overflow-y: auto;
}

.max_holder .card .lvl_right {
  left: 360px;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 30px;
  height: 380px;
  line-height: 10px;
  color: #FFF;
  background: rgb(83, 83, 83);
  background: linear-gradient(140deg, rgb(83, 83, 83) 0%, rgb(20, 20, 20) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 60px;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  position: relative;
}

.max_holder .card .lvl_right .lvl_text {
  padding-top: 20px;
  writing-mode: vertical-rl;
  font-weight: 800;
  font-size: 28px;
}

.max_holder .card .info h5 {
  font-size: 20px;
  font-weight: 800;
  margin-top: 25px;
}

.lvl_holder {
  height: 16px;
  border-radius: 16px;
  width: 100%;
  background-color: rgba(0, 0, 0, .45);
  position: relative;
  margin-top: 10px;
}

.lvl_holder .lvl {
  width: 75%;
  background-color: #4A4A4A;
  height: 16px;
  border-radius: 16px;
}

.points {
  position: absolute;
  bottom: 20px;
  right: 10px;
  width: 50px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.edit {
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  color: #FFF;
  border: 1px solid #FFF;
  font-weight: 600;
  display: block;
  margin: 10px auto;
  cursor: pointer;
  font-size: 15px;
  z-index: 2;
  width: fit-content;
}

.edit:hover {
  color: #FFF;
  background-color: #414e54;
}

.import {
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #000;
  color: #FFF;
  font-weight: 600;
  display: block;
  margin: 10px auto;
  cursor: pointer;
  font-size: 15px;
  width: fit-content;
}

.import:hover {
  color: #000;
  background-color: #00FF00;
}

.import_tag {
  color: #333;
  position: fixed;
  bottom: 80px;
  right: 34px;
  font-size: 11px;
  text-align: center;
  max-width: 200px;
  z-index: 3;
  border-radius: 10px;
  border: 1px solid #d6d8db;
  backdrop-filter: blur(6px);
  padding: 10px 20px 5px 20px;
  background-color: rgba(255, 255, 255, .2);
}

.purchase {
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #222;
  color: #eeb400;
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  width: fit-content;
  backdrop-filter: blur(6px);
  padding-right: 32px;
  display: block;
  margin: 10px 0 0 -6px;
  position: relative;
}

.purchase img {
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 20px;
}

.purchase:hover {
  background-color: #000;
}

.switcher {
  display: none;
}

.max_holder .card .visual {
  width: 360px;
  height: 540px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.max_holder .card .fighter_name {
  color: #FFF;
  display: block;
  margin: 10px auto;
  text-align: center;
  height: 500px;
  line-height: 112px;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 15px;
  right: 5px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3em;
}

.weightclass {
  position: absolute;
  bottom: 40px;
  left: 0;
  background-color: #000;
  height: 30px;
  line-height: 30px;
  color: #FFF;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 20px;
  text-align: center;
  width: fit-content;
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 2;
}

.mmaclass {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #000;
  height: 30px;
  line-height: 30px;
  color: #FFF;
  font-size: 18px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 20px;
  text-align: center;
  width: fit-content;
  border-bottom-left-radius: 20px;
  border-top-right-radius: 20px;
}

.loader {
  background-color: #FAFAFA;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 8;
  top: 0;
  left: 0;
}

.abount_units {
  position: absolute;
  bottom: 40px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  line-height: 15px;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  padding: 10px 20px;
  text-align: center;
  width: fit-content;
  border-radius: 8px;
  backdrop-filter: blur(6px);
  z-index: 3;
  text-align: center;
}

.fighter_card {
  display: block;
  margin: 20px auto 40px auto;
  width: 720px;
  position: relative;
  border-radius: 20px;
  padding: 0;
}

.fighter_card .fighter_visual {
  display: inline-block;
  vertical-align: top;
  margin: 0;
  padding: 0;
  width: 360px;
  height: 540px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.fighter_card .fighter_visual .mask {
  width: 360px;
  height: 540px;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.fighter_card .fighter_visual .nickname {
  color: #FFF;
  display: block;
  margin: 10px auto;
  text-align: center;
  height: 500px;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 15px;
  right: 5px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 3em;
}

.fighter_card .fighter_info {
  display: inline-block;
  vertical-align: top;
  width: 310px;
  height: 540px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%);
  padding: 0 25px;
  margin: 0;
}

.event_poster {
  display: block;
  margin: 20px auto;
  width: 360px;
  border-radius: 10px;
}

.event_visual {
  display: block;
  margin: 180px auto 20px auto;
  width: 800px;
  border-radius: 10px;
}

.prono_holder {
  padding: 20px 10px 10px 10px;
  background-color: rgba(0, 0, 0, .25);
  width: fit-content;
  display: block;
  margin: 0 auto 40px auto;
  border-radius: 30px;
}

.prono {
  width: 900px;
  display: block;
  margin: 80px auto 20px auto;
  min-height: 200px;
  position: relative;
}

.border_mark {
  border: 1px solid #CACED6;
  border-radius: 20px;
}

.simple_border_mark {
  border: 1px solid #CACED6;
}

.left_shadow {
  box-shadow: rgba(0, 0, 0, 0.1) -4px 0 6px -1px;
}

.reduced-top {
  margin-top: 5px !important;
}

.max-bottom {
  margin-bottom: 60px !important;
}

.prono .shadow {
  position: absolute;
  top: 0;
  left: calc(50% - 52px);
  width: 105px;
}

.prono .event {
  margin: -40px auto 0 auto;
  height: 40px;
  line-height: 40px;
  display: block;
  padding: 0 15px;
  color: #FFF;
  font-weight: 800;
  font-style: italic;
  font-size: 25px;
  text-align: center;
  background-color: #1b2029;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: fit-content;
}

.prono .vs {
  position: absolute;
  top: 160px;
  left: calc(50% - 30px);
  width: 60px;
  height: 50px;
  line-height: 45px;
  transform: skewX(-15deg);
  font-size: 50px;
  text-align: center;
  color: #CCC;
  font-weight: 800;
  z-index: 3;
}

.prono .no_profile {
  top: 80px !important;
}

.prono .right_pts {
  position: absolute;
  padding: 10px 8px 0 8px;
  bottom: 15px;
  left: 24px;
  width: fit-content;
  height: 55px;
  background-color: #CACED6;
  font-size: 16px;
  text-align: center;
  color: #000;
  font-weight: 800;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  line-height: 22px;
  z-index: 2;
}

.prono .right_pts:hover {
  background-color: #00FF00;
}

.prono .right_pts img {
  width: 15px;
  position: absolute;
  right: 5px;
  top: 15px
}

.prono .left_pts {
  position: absolute;
  padding: 10px 8px 0 8px;
  bottom: 15px;
  right: 24px;
  width: fit-content;
  height: 55px;
  background-color: #CACED6;
  font-size: 16px;
  text-align: left;
  color: #000;
  font-weight: 800;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  line-height: 22px;
  z-index: 2;
}

.prono .left_pts:hover {
  background-color: #00FF00;
}

.prono .left_coins {
  position: absolute;
  padding: 4px 24px 4px 10px;
  bottom: 88px;
  right: 24px;
  width: fit-content;
  height: auto;
  background-color: #111;
  font-size: 13px;
  text-align: left;
  color: #FFF;
  font-weight: 600;
  border-radius: 12px;
  text-align: center;
  line-height: 22px;
  z-index: 2;
  text-align: left;
}

.prono .left_coins img {
  width: 15px;
  position: absolute;
  right: 5px;
  top: 7px
}

.prono .right_coins {
  position: absolute;
  padding: 4px 24px 4px 10px;
  bottom: 88px;
  left: 24px;
  width: fit-content;
  height: auto;
  background-color: #111;
  font-size: 13px;
  text-align: left;
  color: #FFF;
  font-weight: 600;
  border-radius: 12px;
  text-align: center;
  line-height: 22px;
  z-index: 2;
  text-align: left;
}

.prono .right_coins img {
  width: 15px;
  position: absolute;
  right: 5px;
  top: 7px
}

.prono .left_part {
  width: 350px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
  min-height: 200px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%);
  border-radius: 20px;
  color: #FFF;
  z-index: 2;
  position: relative;
}

.prono .right_part {
  width: 350px;
  padding: 0 20px;
  display: inline-block;
  vertical-align: top;
  min-height: 200px;
  background: linear-gradient(-170deg, #444d5f 0%, #0c0e12 100%);
  border-radius: 20px;
  color: #FFF;
  z-index: 2;
  margin-left: 80px;
  position: relative;
}

.prono .pill {
  display: inline-block;
  margin-right: 5px;
  border-radius: 5px;
  padding: 2px 6px;
  background-color: #AAA;
  font-weight: 600;
  font-size: 12px;
  color: #000;
}

.prono .selected {
  color: #FFBB00 !important;
  min-height: 200px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
}

.prono .selected_win {
  color: #00FF00 !important;
  min-height: 200px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
}

.prono .selected_draw {
  color: #fff !important;
  min-height: 200px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
}

.prono .selected_loss {
  color: #ff4e4e !important;
  min-height: 200px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
}

.prono .for_validation {
  color: #00FF00 !important;
  min-height: 200px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
}

.prono .entry {
  display: block;
  margin: 5px 0 0 0;
  font-size: 18px;
  font-weight: 700;
}

.prono .data {
  display: block;
  margin: 5px 0 0 0;
  font-size: 14px;
  font-weight: 500;
}

.prono .date {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 14px;
  font-weight: 500;
}

.status {
  position: absolute;
  top: 75px;
  right: 30px;
  font-size: 15px;
  font-weight: 800;
  line-height: 25px;
  height: 25px;
  padding: 0 8px;
  text-align: center;
  color: #000;
  border-radius: 12px;
}

.event_status {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 12px;
  font-weight: 800;
  line-height: 20px;
  height: 20px;
  padding: 0 8px;
  text-align: center;
  color: #000;
  border-radius: 12px;
}

.prono .fighter {
  position: absolute;
  height: 160px;
  right: 0;
  bottom: 10px;
}

.prono .right_fighter {
  position: absolute;
  height: 160px;
  left: 0;
  bottom: 10px;
}

.prono .fighter_name {
  position: absolute;
  bottom: -45px;
  left: 0;
  height: 40px;
  line-height: 40px;
  display: block;
  padding: 0 15px;
  color: #FFF;
  font-weight: 800;
  font-style: italic;
  font-size: 1.2em;
  text-align: center;
  background-color: #FFF;
  color: #000;
  border-radius: 10px;
  width: calc(100% - 30px);
}

.prono .click {
  height: 40px;
  line-height: 40px;
  display: block;
  padding: 0 15px;
  color: #DDD;
  font-size: 12px;
  text-align: center;
  position: relative;
  align-items: center;
}

.prono .click_right {
  height: 40px;
  line-height: 40px;
  display: block;
  padding: 0 15px;
  color: #DDD;
  font-size: 12px;
  text-align: right;
  position: relative;
  align-items: center;
}

.prono .click svg {
  margin-left: 10px;
}

.prono .click_right svg {
  margin-right: 10px;
}

.win_holder {
  padding: 5px 20px;
  background-color: #FFBB00;
  border-radius: 10px;
  color: #000;
  display: block;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 20px;
}

.booster_holder {
  padding: 5px 20px;
  background-color: #EEE;
  border-radius: 10px;
  color: #000;
  display: block;
  margin: 0 auto;
  width: fit-content;
  margin-bottom: 20px;
}

.win_selecter {
  display: block;
  line-height: 40px;
  display: block;
  margin: 0 auto 10px auto;
  width: fit-content;
}

.win_selecter .left_b {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-right: 2px solid #444d5f;
}

.win_selecter .right_b {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-left: 2px solid #444d5f;
}

.win_selecter .win_type {
  display: inline-block;
  padding: 0 20px;
  line-height: 40px;
  font-size: 13px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  cursor: pointer;
  background-color: #111;
  border-radius: 8px;
  margin: 2px;
}

.win_selecter .win_type:hover {
  background-color: #00FF00;
  color: #000;
}

.win_selecter .booster_type {
  display: inline-block;
  padding: 0 30px 0 12px;
  line-height: 40px;
  font-size: 13px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
  cursor: pointer;
  background-color: #111;
  border-radius: 8px;
  margin: 2px;
  position: relative;
}

.win_selecter .booster_type:hover {
  background-color: #00FF00;
  color: #000;
}

.win_selecter .booster_type .gtcoins {
  position: absolute;
  right: 12px;
  top: 14px;
  width: 12px;
}

.win_about {
  display: block;
  color: #EEE;
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 10px auto;
  width: fit-content;
}

.win_info {
  display: block;
  color: #333;
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 0 auto 10px auto;
  text-align: left;
}

.zone-prono {
  direction: rtl;
  overflow-x: hidden;
  width: 260px;
  padding-top: 0 !important;
}

.reset_line {
  direction: ltr !important;
}

.editor {
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: inline-block;
  margin: 20px auto;
  width: 360px;
  height: 580px;
  position: relative;
  padding: 10px 25px;
}

.editor .fighter {
  position: absolute;
  right: -290px;
  bottom: 0;
  height: 600px;
  border-radius: 25px;
  z-index: -1;
}

.text_editor {
  padding: 8px 20px;
  border: 1px solid #DCDFE2;
  border-radius: 25px;
  width: 300px;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0;
  background-color: #FFF;
}

.generator {
  display: inline-block;
  margin: 11px 0 10px -30px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background-color: #000;
  color: #FFF;
  text-align: center;
  line-height: 29px;
  font-size: 12px;
  cursor: pointer;
}

.global_selecter {
  padding: 8px 20px;
  border-radius: 25px;
  width: fit-content;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0;
  background-color: #dee2e9;
  border: 1px solid #c1c7d3;
  color: #000;
  margin: 8px;
  display: inline-block;
}

.editor .tips {
  display: block;
  margin: 0 0 0 20px;
  font-size: 12px;
  font-weight: 500;
  color: #7e7e7e;
  text-align: left;
}

.editor .sub_tips {
  display: block;
  margin: -4px 0 -7px 0;
  font-size: 11px;
  font-weight: 500;
  color: #7e7e7e;
  text-align: right;
}

.submit {
  margin: 20px 0 0 0;
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #000;
  color: #DCDFE2;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  font-size: 15px;
}

.submit:hover {
  color: #FFF;
  background-color: #000;
}

.editor .avatar {
  display: block;
  margin: 5px 10px;
  border-radius: 20px;
  width: 125px;
  height: 125px;
  background-color: #CCC;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}

.confirm {
  margin: 20px auto;
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #000;
  color: #EEE;
  font-weight: 600;
  display: block;
  cursor: pointer;
  font-size: 15px;
  width: fit-content;
}

.confirm:hover {
  color: #FFF;
  background-color: #000;
}

.card-fighter {
  margin-top: 150px !important;
}

.card-store {
  margin: 40px !important;
  display: inline-block !important;
}

.card-editor {
  display: inline-block;
  vertical-align: top;
  margin: 20px 0 20px 2px;
  width: 400px;
  height: 600px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
}

.card-editor .fighter_name {
  color: #FFF;
  display: block;
  margin: 10px auto;
  text-align: center;
  height: 580px;
  line-height: 130px;
  writing-mode: vertical-rl;
  position: absolute;
  bottom: 10px;
  right: 5px;
  text-transform: uppercase;
  font-weight: 800;
  font-size: 1em;
  word-break: break-all;
}

.card-editor .visual {
  width: 400px;
  height: 600px;
  border-radius: 25px;
  position: absolute;
  top: 0;
  left: 0;
}

.full-border-radius {
  border-radius: 25px;
}

.intall_ios {
  position: fixed;
  z-index: 2;
  border-radius: 20px;
  width: 300px;
  height: 280px;
  top: 90px !important;
  left: calc(50% - 160px);
  background-color: #000;
  border: 1px solid #222;
  text-align: left;
  color: #FFF;
}

.intall_ios .logo {
  width: 80px;
  margin: 20px 20px 0 20px;
}

.intall_ios .title {
  display: inline-block;
  font-size: 16px;
  font-weight: 800;
  vertical-align: top;
  padding-top: 32px;
}

.intall_ios .share {
  width: 12px;
  margin-left: 5px;
}

.intall_ios .close {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  right: 10px;
}

.search {
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%);
  border-radius: 20px;
  min-height: 500px;
  width: 600px;
  display: block;
  margin: 0 auto;
  padding: 15px 20px;
}

.search .search_editor {
  display: block;
  margin: 10px 0;
  width: 100%;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  font-weight: 500;
  border-radius: 20px;
  padding: 0 20px;
  border: 0;
}

.tabs {
  display: block;
  margin: 20px auto 10px auto;
}

.tab {
  display: inline-block;
  padding: 0 8px;
  width: calc(50% - 20px);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #CACED6;
  color: #CACED6;
}

.tab_active {
  display: inline-block;
  padding: 0 8px;
  width: calc(50% - 20px);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #FFF;
  border-bottom: 2px solid #FFF;
  cursor: pointer;
}

.tab_3 {
  display: inline-block;
  padding: 0 8px;
  width: calc(33% - 20px);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  cursor: pointer;
  border-bottom: 1px solid #CACED6;
  color: #CACED6;
}

.tab_active_3 {
  display: inline-block;
  padding: 0 8px;
  width: calc(33% - 20px);
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #FFF;
  border-bottom: 2px solid #FFF;
  cursor: pointer;
}

.search .profile_holder {
  padding: 2px;
  background-color: #FFF;
  border-radius: 8px;
  width: 200px;
  display: block;
}

.search .profile {
  display: block;
  margin: 8px;
  cursor: pointer;
}

.search .profile .avatar {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  background-position: center center;
  background-size: cover;
}

.search .profile .fighter_visual {
  display: inline-block;
  margin-right: 10px;
  vertical-align: top;
  border-radius: 8px;
  width: 64px;
  height: 100px;
  background-position: center center;
  background-size: cover;
}

.search .profile .username {
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  vertical-align: top;
}

.search .profile .fighters {
  line-height: 20px;
  font-size: 12px;
  font-weight: 400;
  color: #73767E;
  display: block;
  text-transform: capitalize;
}

.toolbox {
  display: block;
  position: fixed;
  z-index: 2;
  left: calc(50vw + 200px);
  top: 150px;
  width: 300px;
  min-height: 550px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  margin-left: 5%;
  margin-top: 40px;
  padding: 15px;
  padding-bottom: 30px;
  border: 1px solid #CACED6;
  background: rgba(149, 154, 163, .3);
}

.filter {
  height: 100px;
  margin: 10px;
  cursor: pointer;
}

.divider {
  height: 100px;
  margin: 10px;
  width: 1px;
  background: #EEE;
  display: inline-block;
  vertical-align: top;
}

.header {
  display: inline-block;
  vertical-align: top;
  background: rgba(255, 255, 255, .3);
  border: 1px solid #CACED6;
  color: #000;
  width: calc(100% + 10px);
  border-radius: 20px;
  padding: 10px 0;
  margin: 10px 20px;
  width: fit-content;
}

.header select {
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
  color: #000;
  font-weight: 700;
  display: block;
  margin: 10px 0;
  cursor: pointer;
  font-size: 15px;
  z-index: 2;
  font-family: 'Poppins', sans-serif;
  width: fit-content;
}

.toolbox .closing {
  display: none;
}

.full {
  display: inline-block !important;
  vertical-align: top !important;
  width: calc(95% - 360px) !important;
  margin: 0 5px 0 25px !important;
}

.activer {
  display: none;
}

.league {
  width: 800px;
  display: block;
  margin: 40px auto 20px auto;
  height: auto;
  position: relative;
  text-align: left;
  padding: 20px 25px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%);
  border-radius: 20px;
  position: relative;
  height: calc(200px - 40px);
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px 1px;
}

.league .teaser {
  position: absolute;
  top: 0;
  right: 0;
  width: 280px;
  height: 200px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background-position: center top;
  background-size: cover;
}

.quarter_one {
  border-top-left-radius: 20px;
  width: calc(50% - 10px);
  height: 120px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  margin: 8px 0 0 0;
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.quarter_two {
  border-top-right-radius: 20px;
  width: calc(50% - 10px);
  height: 120px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  margin: 8px 0 0 0;
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.quarter_three {
  border-bottom-left-radius: 20px;
  width: calc(50% - 10px);
  height: 120px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  margin: -2px 0 0 0;
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.quarter_four {
  border-bottom-right-radius: 20px;
  width: calc(50% - 10px);
  height: 120px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
  margin: -2px 0 0 0;
  border: 4px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.quarter_active {
  border: 4px solid #00FF00;
}

.participants .profile {
  padding: 5px;
  display: block;
  margin: 30px auto 0 auto;
  width: 480px;
  background-color: #FFF;
  border-radius: 10px;
  cursor: pointer;
}

.participants .profile .avatar {
  width: 82px;
  height: 82px;
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  color: #000;
  line-height: 42px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  margin-left: 5px;
  background-position: center center;
  background-size: cover;
}

.participants .profile .info_profile {
  width: calc(100% - 200px);
  height: 72px;
  text-align: left;
  line-height: 28px;
  font-size: 18px;
  font-weight: 600;
  color: #000;
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  padding-top: 10px;
}

.resized {
  width: 95px;
  height: 80px;
  padding-top: 20px;
  line-height: 30px;
  font-size: 28px !important;
}

.resized .minimized {
  font-size: 14px !important;
  font-weight: 500;
  display: block;
  margin: 0 auto;
}

.follower .profile {
  padding: 5px;
  display: block;
  margin: 20px auto 0 auto;
  width: 480px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid #c1c7d3;
}

.follower .profile .avatar {
  width: 42px;
  height: 42px;
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  color: #000;
  line-height: 42px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
}

.follower .profile .big_avatar {
  width: 62px;
  height: 62px;
  background: rgb(229, 229, 229);
  background: linear-gradient(140deg, rgb(229, 229, 229) 0%, rgb(173, 173, 173) 100%);
  text-align: center;
  font-size: 22px;
  font-weight: 800;
  color: #000;
  line-height: 42px;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
}

.follower .profile .info_profile {
  width: calc(100% - 200px);
  text-align: left;
  line-height: 24px;
  font-size: 14px;
  font-weight: 600;
  color: #000;
  display: inline-block;
  vertical-align: top;
  margin-left: 15px;
  padding-top: 10px;
}

.follower .profile .view {
  float: right;
  height: 42px;
  line-height: 42px;
  padding: 0 10px;
  background-color: #CACED6;
  color: #222;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 700;
}

.follower .profile .view:hover {
  background-color: #c1c7d3;
}

.tagline {
  font-size: 12px;
  font-weight: 500;
  color: #434854;
}

.banner_sub {
  display: block;
  margin: 10px auto;
  border-radius: 20px;
  background-position: center top;
  background-size: cover;
  width: 1050px;
  height: 349px;
}

.sub_mobile {
  display: none;
}

.subscribe {
  backdrop-filter: blur(10px);
  display: block;
  margin: 0 auto;
  height: calc(349px - 20px);
  width: fit-content;
  max-width: 33%;
  position: relative;
  padding: 10px 25px;
  border-left: 3px solid #c6cdd9;
  border-right: 3px solid #c6cdd9;
}

.subscribe_button {
  margin: 20px 0 0 0;
  border-radius: 25px;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background-color: #c6cdd9;
  color: #000;
  font-weight: 600;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  font-size: 15px;
  width: fit-content;
}

.subscribe_button:hover {
  background-color: #bdc4cf;
}

.minifighter_folder {
  display: block;
  width: 820px;
  position: relative;
  height: 400px;
  margin: 0 auto;
}

.minifighter1 {
  position: absolute;
  z-index: 3;
  left: 10px;
  width: 267px;
  height: 400px;
  border-radius: 10px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.minifighter2 {
  position: absolute;
  z-index: 2;
  top: 25px;
  left: 240px;
  width: 234px;
  height: 350px;
  border-radius: 10px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.minifighter3 {
  position: absolute;
  z-index: 1;
  top: 45px;
  left: 440px;
  width: 208px;
  height: 312px;
  border-radius: 10px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.minifighter4 {
  position: absolute;
  z-index: 0;
  top: 65px;
  left: 620px;
  width: 186px;
  height: 279px;
  border-radius: 10px;
  background-color: #E5E8EE;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.rarity_tag {
  position: absolute;
  bottom: 20px;
  left: 24px;
  width: fit-content;
  padding: 2px 8px;
  border-radius: 20px;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  background-color: #EEE;
  z-index: 2;
}

.fixed_rarity_tag {
  position: absolute;
  top: -28px;
  left: 0;
  width: 100px;
  padding: 2px 8px;
  border-radius: 20px;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  background-color: #EEE;
  z-index: 2;
}

.aligned_rarity_tag {
  width: 100px;
  padding: 2px 8px;
  border-radius: 20px;
  color: #000;
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  background-color: #EEE;
  margin-top: 5px;
}

.discord {
  width: 220px;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #5865f2;
  position: absolute;
  right: 40px;
  top: 10px;
  height: 60px;
  display: block;
  margin: 10px auto;
}

.discord .discord_logo {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 40px;
}

.discord .discord_join {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 140px;
  font-size: 16px !important;
  font-weight: 700;
}

.discord .discord_join span {
  display: block;
  font-size: 12px !important;
  font-weight: 400;
}

@media screen and (max-width: 940px) {
  .banner_goat {
    height: 70vw;
    margin-left: -22vw;
  }
}

@media screen and (max-width: 760px) {
  .page {
    position: relative;
    padding-top: 80px;
    height: calc(100vh - 120px);
    background: linear-gradient(90deg, #808a8c 0%, #4b565c 100%);
    color: #FFF;
    padding-bottom: 40px;
  }

  h4 {
    color: #EEE;
  }

  .hide_mobile {
    display: none !important;
  }

  .responsive-text-right {
    text-align: left !important;
  }

  .responsive-m-left {
    margin-left: auto !important;
  }

  .responsive-m-right {
    margin-right: auto !important;
  }

  .thegoat {
    width: 68%;
    display: block;
    position: absolute;
    bottom: 30px;
    left: 16%;
  }

  .banner_goat {
    width: 100vw;
    height: 380px;
    margin: 0;
    background-size: cover;
  }

  .signup {
    border: 1px solid #CACED6;
  }

  .banner_goat .blurb {
    position: relative;
    right: 0;
    top: calc(88vw + 20px);
    width: calc(100vw - 40px);
    padding: 0 20px;
    color: #000;
  }

  .banner_goat .simple_text {
    color: #000;
  }

  .banner_goat .blurb h1 {
    color: #000;
  }

  .next_lvl {
    color: #CCC;
  }

  .minimizer {
    max-width: 100% !important;
    margin-left: 0 !important;
  }

  .min_holder {
    width: 100%;
    max-width: none;
  }

  .max_holder {
    width: 100%;
    margin: 20px auto;
  }

  .max_controller {
    width: 90%;
  }

  .max_holder .card {
    display: block;
    margin: 20px 0;
    width: 53.33vh;
    height: 80vh;
    background: rgba(0, 0, 0, 0);
    align-content: end;
    border-radius: 0;
    box-shadow: none;
  }

  .max_holder .card hr {
    height: 1px;
    background: #AAA;
    border: none;
  }

  .fontawesome svg {
    color: #FFF;
  }

  .max_holder .card_event {
    display: block;
    margin: 20px auto 240px auto;
    width: 90vw;
    height: 135vw;
    background-position: center center;
  }

  .max_holder .card .fighter_name {
    height: calc(135vw - 25px);
    font-size: 11vw;
  }

  .max_holder .card .visual {
    width: 53.33vh;
    height: 80vh;
  }

  .max_holder .card .info {
    width: 280px;
    padding-top: 0;
    left: 5vw;
    top: auto;
    bottom: 30px;
    border-radius: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    min-height: auto;
    max-height: 420px;
    background: linear-gradient(-20deg, rgba(68, 77, 95, .5) 0%, rgba(12, 14, 18, .5) 100%) !important;
    backdrop-filter: blur(10px);
    z-index: 2;
    padding-bottom: 20px;
  }

  .max_holder .card_event .info {
    bottom: -160px;
    background: #0c0e12 !important;
    backdrop-filter: none;
    left: calc(50% - 165px);
  }

  .max_holder .card .info h5 {
    margin-top: 17px;
  }

  .max_holder .card .longer {
    top: 130vw;
    height: 500px;
  }

  .max_holder .card .info_right {
    left: 5vw !important;
    overflow-y: auto;
  }

  .edit {
    background-color: rgba(0, 0, 0, 0);
    color: #FFF;
    border: 1px solid #FFF;
    font-size: 3.7vw;
    margin-top: 14px;
  }

  .edit:hover {
    color: #FFF;
    background-color: #000;
  }

  .max_holder .card2 {
    display: none;
  }

  .max_holder .card3 {
    display: none;
  }

  .max_holder .card4 {
    display: none;
  }

  .label {
    color: #DDD;
  }

  .value {
    color: #FFF;
  }

  .vs_fighters {
    color: #FFF;
  }

  .min_notice {
    color: #EEE;
  }

  .confirm {
    padding: 2vw 5vw;
    font-size: 4vw;
  }

  .card-select {
    width: 90vw !important;
    height: 135vw !important;
    display: block !important;
    margin: 25px auto !important;
    background-size: cover !important;
    background-position: center center;
  }

  .card-select:hover {
    border: 0 !important;
    margin: 25px auto !important;
    cursor: pointer;
  }

  .event_visual {
    display: block;
    width: 90%;
    margin-top: 120px;
  }

  .prono_holder {
    width: calc(100% - 40px);
    padding: 10px !important;
  }

  .prono {
    width: 90vw !important;
    display: block;
    margin: 80px auto 20px auto;
    position: relative;
    min-height: auto;
  }

  .prono .event {
    margin: -40px auto 0 auto;
    height: 40px;
    line-height: 40px;
    display: block;
    padding: 0 15px;
    color: #FFF;
    font-weight: 800;
    font-style: italic;
    font-size: 17px;
    text-align: center;
    background-color: #1b2029;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: fit-content;
  }

  .prono .vs {
    position: relative;
    display: block;
    top: 24px;
    left: calc(50% - 30px);
  }

  .prono .no_profile {
    top: 20px !important;
  }

  .prono .right_pts {
    bottom: 15px;
    left: auto;
    right: 20px;
  }

  .prono .left_pts {
    bottom: 15px;
    right: 20px;
  }

  .prono .left_coins {
    right: 20px;
  }

  .prono .entry {
    font-size: 17px;
  }

  .prono .left_part {
    width: calc(100% - 40px);
    display: block;
    padding: 2px 20px;
    margin: 0;
    height: auto !important;
  }

  .prono .right_part {
    width: calc(100% - 40px);
    display: block;
    padding: 2px 20px;
    margin: 42px auto 0 auto;
    height: auto !important;
  }

  .prono .date {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 100%;
    text-align: center;
  }

  .status {
    position: absolute;
    bottom: auto;
    top: 85px;
    left: auto;
    right: 38px;
    font-size: 12px;
    border-radius: 12px;
  }

  .prono .fighter {
    position: absolute;
    width: auto;
    left: auto;
    height: 180px;
    right: 0;
    bottom: 0px;
  }

  .prono .fighter_name {
    position: absolute;
    font-size: 1em;
    left: 0;
  }

  .prono .shadow {
    position: absolute;
    top: 140px;
    left: auto;
    width: 60px;
    right: 5px;
  }

  .align-right {
    width: 50vw;
  }

  .align-left {
    width: 50vw;
  }

  .profile_news {
    width: calc(90vw - 58px) !important;
  }

  .profile_news h4 {
    font-size: 14px;
    margin-top: 5px;
  }

  .editor {
    width: calc(100% - 86px);
    display: block;
    margin: calc(120vw + 100px) auto 30px auto;
    border-radius: 25px;
    min-height: auto;
    height: auto;
    padding-bottom: 20px;
    background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%);
  }

  .card-editor {
    border-radius: 25px;
    position: absolute;
    margin-top: 120px;
    top: 180px;
    left: 5vw;
    width: 90vw;
    height: 135vw;
    z-index: 0;
    background-color: #7c8688;
  }

  .card-editor .visual {
    width: 90vw;
    height: 135vw;
  }

  .card-editor .fighter_name {
    height: 135vw;
    line-height: 30vw;
    bottom: -3vw;
  }

  .editor .fighter {
    position: absolute;
    right: -220px;
    bottom: 0;
    height: 600px;
  }

  .text_editor {
    width: 75%;
  }

  .zone-prono {
    display: none;
  }

  .fighter_card {
    width: calc(100% - 20px);
    height: auto;
  }

  .fighter_card .fighter_visual {
    display: block;
    width: calc(90vw - 20px);
    height: calc(132vw - 18px);
    border-radius: 20px;
    background-color: #7c8688;
  }

  .fighter_card .fighter_visual .mask {
    display: none;
  }

  .fighter_card .fighter_visual .nickname {
    display: none;
  }

  .fighter_card .fighter_info {
    margin-top: -80vw;
    padding-top: 20px;
    padding-bottom: 40px;
    display: block;
    width: calc(90vw - 70px);
    height: auto;
    border-radius: 20px;
    position: relative;
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 30%, rgba(0, 0, 0, .25) 80%);
    backdrop-filter: blur(10px);
  }

  .big_profile {
    width: calc(90% - 20px);
    height: auto;
    padding-bottom: 30px;
    text-align: center;
    background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
    margin-top: 80px;
  }

  .big_profile .avatar {
    width: 140px;
    height: 140px;
  }

  .profile_action {
    border: 1px solid #FFF;
  }

  .profile_action:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 2px solid #00FF00;
    color: #00FF00;
  }

  .logout {
    color: #DDD;
    text-decoration: none;
  }

  .web_f-right {
    float: none;
  }

  .switcher {
    display: block;
    border-radius: 25px;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #FFBB00;
    color: #000;
    font-weight: 700;
    margin: 10px auto;
    cursor: pointer;
    font-size: 16px;
    width: fit-content;
    position: absolute;
    bottom: -18px;
    right: 20px;
  }

  .switcher:hover {
    color: #000;
    background-color: #ffc31f;
  }

  .card-store {
    width: 90vw !important;
    height: 135vw !important;
    display: block !important;
    margin: 35px auto !important;
  }

  .full-border {
    border-radius: 20px !important;
  }

  .center_mobile {
    text-align: center !important;
    display: block !important;
    margin: 2px auto !important;
    float: none !important;
  }

  .intall_ios {
    width: 90vw;
    left: 3vw;
  }

  .search {
    width: calc(90% - 10px);
    margin-top: -40px !important;
    padding-top: 30px;
    min-height: calc(100vh - 100px);
    background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
  }

  .huge {
    font-size: 9vw !important;
  }

  .toolbox {
    display: block;
    width: calc(90vw - 30px);
    margin-left: 0 !important;
    position: fixed;
    z-index: 2;
    left: 5vw;
    top: 100px;
    height: 500px;
    overflow-y: auto;
    background-color: #FFF;
  }

  .toolbox .closing {
    display: block;
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 24px;
    cursor: pointer;
  }

  .full {
    display: block !important;
    width: 100% !important;
    margin: 25px auto !important;
  }

  .activer {
    display: block;
    position: fixed;
    z-index: 2;
    top: 340px;
    left: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: rgba(0, 0, 0, .4);
    backdrop-filter: blur(10px);
    width: 60px;
    height: 60px;
    line-height: 70px;
    color: #FFF;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    cursor: pointer;
  }

  .league {
    width: calc(90% - 20px);
    padding-top: 230px;
    height: auto;
    background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
  }

  .league .teaser {
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 0;
  }

  .banner_sub {
    border-radius: 0;
    width: 100%;
  }

  .subscribe {
    backdrop-filter: blur(10px);
    max-width: 90%;
    border-left: 0;
    border-right: 0;
  }

  .banner_sub {
    margin: 0 auto;
  }

  .sub_mobile {
    display: block;
    margin: 0;
  }

  .participants .profile {
    width: 100%;
  }

  .participants .profile .avatar {
    width: 62px;
    height: 62px;
    font-size: 18px;
  }

  .participants .profile .info_profile {
    height: 42px;
    line-height: 21px;
    font-size: 14px;
  }

  .resized {
    height: 50px;
    width: 68px;
    padding-top: 12px;
    line-height: 20px;
    font-size: 22px !important;
  }

  .resized .minimized {
    font-size: 11px !important;
  }

  .follower .profile {
    width: 90%;
  }

  .launch_white {
    border: 1px solid #CACED6;
  }

  .left_shadow {
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px 1px;
  }

  .starter {
    margin-top: 30px !important;
  }

  .min-ft-14 {
    font-size: 11px !important;
  }

  .post-ft-14 {
    font-size: 13px !important;
    font-weight: 500 !important;
  }

  .comment-ft-14 {
    font-size: 12px !important;
    font-weight: 400 !important;
  }

  .top_mobile_logo {
    width: 150px;
    display: block;
    margin: 20px auto;
  }

  .import_tag {
    right: 10px;
    bottom: 70px;
  }

  .minifighter_folder {
    display: block;
    width: 100vw;
    position: relative;
    height: 65vw;
  }

  .minifighter1 {
    position: absolute;
    z-index: 3;
    left: 2vw;
    width: 40vw;
    height: 60vw;
    border-radius: 10px;
    background-color: #E5E8EE;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .minifighter2 {
    position: absolute;
    z-index: 2;
    top: 3.5vw;
    left: 30vw;
    width: 36vw;
    height: 54vw;
    border-radius: 10px;
    background-color: #E5E8EE;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .minifighter3 {
    position: absolute;
    z-index: 1;
    top: 7vw;
    left: 53vw;
    width: 32vw;
    height: 48vw;
    border-radius: 10px;
    background-color: #E5E8EE;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .minifighter4 {
    position: absolute;
    z-index: 0;
    top: 10vw;
    left: 75vw;
    width: 28vw;
    height: 42vw;
    border-radius: 10px;
    background-color: #E5E8EE;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  }

  .blackshader {
    position: fixed;
    z-index: -1;
    top: 20px;
    left: 0;
    height: calc(100vh - 80px);
    width: 100vw;
    background: linear-gradient(0deg, rgb(215, 215, 232) 0%, rgb(255, 255, 255) 80%);
  }

  .main_desc {
    padding: 1px 10px;
    margin: 0 auto;
    width: calc(100vw - 20px);
    background: #0c0e12;
    border-radius: 20px;
    color: #FFF !important;
    z-index: 2;
  }

  .main_desc .reducer {
    margin: 20px;
    font-size: 6vw;
    font-weight: 400 !important;
  }

  .border_mark {
    border: 0;
  }

  .simple_border_mark {
    border: 0;
  }

  .cancel {
    border: 2px solid #FFF;
  }

  .search .tab {
    border-bottom: 1px solid #CACED6;
    color: #CACED6;
  }

  .search .tab_active {
    color: #FFF;
    border-bottom: 2px solid #FFF;
  }

  .mobile-h-auto {
    min-height: auto !important;
    height: auto !important;
    padding-bottom: 20px !important;
  }

  .card-fighter {
    margin-top: 40px !important;
  }
}

.notification {
  font-family: 'Poppins', sans-serif !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  margin-right: 20px;
}

.notification:before {
  top: 25px !important;
}

.notification-success {
  background-color: #00FF00 !important;
  color: #000 !important;
  font-weight: 700;
}

.logo_mobile {
  position: absolute;
  width: 120px;
  left: calc(50% - 60px);
  top: 10px;
}

@media screen and (max-width: 920px) and (orientation: landscape) {
  body {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }

  .page {
    height: calc(100vw - 120px);
  }

  .banner_goat {
    width: 100vh;
    height: 100vw;
  }

  .banner_goat .blurb {
    top: calc(88vh + 20px);
    width: calc(100vh - 40px);
  }

  .max_holder {
    width: 100vh;
  }

  .max_holder .card {
    width: 53.33vw;
    height: 80vw;
  }

  .max_holder .card .visual {
    width: 53.33vw;
    height: 80vw;
  }

  .max_holder .card_event {
    width: 90vh;
    height: 135vh;
    background-position: center center;
  }

  .max_holder .card .fighter_name {
    height: calc(135vh - 25px);
    font-size: 11vh;
  }

  .max_holder .card .info {
    left: 5vh;
  }

  .max_holder .card .longer {
    top: 130vh;
  }

  .max_holder .card .info_right {
    left: 5vh !important;
  }

  .edit {
    font-size: 3.7vh;
  }

  .confirm {
    padding: 2vh 5vh;
    font-size: 4vh;
  }

  .card-select {
    width: 90vh !important;
    height: 135vh !important;
  }

  .prono {
    width: 90vh !important;
  }

  .profile_news {
    width: calc(90vh - 58px) !important;
  }

  .editor {
    margin: calc(120vh + 100px) auto 30px auto;
  }

  .card-editor {
    left: 5vh;
    width: 90vh;
    height: 135vh;
  }

  .card-editor .visual {
    width: 90vh;
    height: 135vh;
  }

  .card-editor .fighter_name {
    height: 135vh;
    line-height: 30vh;
    bottom: -3vh;
  }

  .fighter_card .fighter_visual {
    width: calc(90vh - 20px);
    height: calc(132vh - 18px);
  }

  .fighter_card .fighter_info {
    margin-top: -80vh;
    width: calc(90vh - 70px);
  }

  .full {
    width: calc(95vh - 360px) !important;
  }

  textarea {
    width: 100% !important;
    height: 100px !important;
  }
}

.ranking_container {
  display: block;
  margin: 20px auto;
  max-width: 990px;
}

.ranking_container .filter_holder {
  display: block;
  margin: 20px auto;
  width: fit-content;
  background-color: #E4E4E4;
  border-radius: 12px;
}

.ranking_container .filter_holder .filter_button {
  display: inline-block;
  background-color: #E4E4E4;
  margin: 2px;
  width: 160px;
  color: #000;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.ranking_container .filter_holder .filter_active {
  display: inline-block;
  background: linear-gradient(140deg, rgb(200, 200, 200) 0%, rgb(173, 173, 173) 100%);
  margin: 2px;
  width: 160px;
  color: #000;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.ranking_container .rank_select {
  display: block;
  margin: 20px auto;
  border-radius: 20px;
  width: 324px;
  color: #FFF;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  -webkit-appearance: none;
  outline-width: 0;
  outline: none;
  font-family: 'Poppins', sans-serif;
  background-color: rgba(0, 0, 0, .25);
  border: 0;
}

.ranking_container a {
  text-decoration: none;
  color: #00FF00;
}

.ranking_container .second {
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
  width: 300px;
  padding: 0 10px;
}

.ranking_container .first {
  display: inline-block;
  vertical-align: top;
  width: 300px;
  padding: 0 10px;
}

.ranking_container .third {
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
  width: 300px;
  padding: 0 10px;
}

.ranking_container .fighter_visual {
  background-position: center top;
  background-size: cover;
  width: 300px;
  height: 460px;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  z-index: 2;
}

.ranking_container .news {
  height: 380px !important;
}

.ranking_container .profile_board {
  display: block;
  width: 280px;
  height: 260px;
  border-radius: 10px;
  margin: -260px auto 0 auto;
  padding: 1px 10px 0 10px;
  background: rgba(0, 0, 0, .4);
  backdrop-filter: blur(6px);
  z-index: 0;
  color: #FFF;
}

.ranking_container .shortened {
  height: 220px !important;
  margin: -220px auto 0 auto;
}

.ranking_container .profile_board .centered_rank {
  margin: -20px auto 0 auto !important;
  display: block !important;
}

.ranking_container .profile_board .centered_rank img {
  height: 40px;
  display: block;
  margin: 5px auto;
}

.ranking_container .back_separator {
  width: 200px;
  height: 1px;
  background-color: #555;
  display: block;
  margin: 0 auto;
}

.ranking_container .rank_list {
  padding: 0 !important;
  border-radius: 12px;
  background: linear-gradient(170deg, #444d5f 0%, #0c0e12 100%) !important;
  margin: 10px auto;
  width: calc(100% - 40px);
}

.ranking_container .rank_list .table_fighter {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-position: center top;
  background-size: cover;
  display: inline-block;
  margin: 5px 20px 0 5px;
  cursor: pointer;
}

.ranking_container .rank_list .wider {
  width: 120px !important;
  height: 120px !important;
}

.ranking_container .rank_list .table_user {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  background-position: center top;
  background-size: cover;
  display: inline-block;
  margin: 5px 20px 0 5px;
  cursor: pointer;
}

.ranking_container .rank_list .table_min_user {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-position: center top;
  background-size: cover;
  display: inline-block;
  margin: 5px 20px 0 5px;
  cursor: pointer;
}

.ranking_container .rank_list .info_rank {
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
  max-width: 700px;
}

.ranking_container .rank_list .info_rank a {
  text-decoration: none;
  color: #000;
  font-weight: 600;
}

.ranking_container .flag {
  top: 3px;
  right: 0;
  display: inline-block;
  margin: 0 5px;
  vertical-align: top;
  position: relative;
  transform: scale(0.7);
}

@media screen and (max-width: 940px) {
  .ranking_container .second {
    display: block !important;
    margin: 20px auto !important;
  }

  .ranking_container .first {
    display: block !important;
    margin: 20px auto !important;
  }

  .ranking_container .third {
    display: block !important;
    margin: 20px auto !important;
  }

  .ranking_container .rank_list .table_fighter {
    height: 160px;
    margin: 5px 10px 0 5px;
  }

  .ranking_container .rank_list .table_user {
    margin: 5px 10px 0 5px;
    width: 62px;
    height: 62px;
  }

  .ranking_container .rank_list .table_min_user {
    margin: 5px 10px 0 5px;
    width: 42px;
    height: 42px;
  }

  .ranking_container .rank_list .info_rank {
    min-width: auto;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 170px);
  }

  .ranking_container .rank_list .reevaluated {
    width: calc(100% - 130px) !important;
  }

  .ranking_container .rank_list .wider {
    width: 26vw !important;
    height: 36vw !important;
  }
}

.gc_store{
  background-color: #4D555B;
  border-radius: 20px;
  padding: 20px 10px 10px 10px;
  width: calc(90% - 20px);
  display: block;
  margin: 10px auto 30px auto;
}

.gc_line{
  background-color: #A1A7A8;
  width: calc(80% - 40px);
  display: block;
  margin: 10px auto 60px auto;
  height: 1px;
}

.gc_store .block1{
  display: inline-block;
  width: 60px;
  vertical-align: top;
}

.gc_store .block1 .gc_img{
  display: block;
  margin: 0 auto;
  width: 38px;
}

.gc_store .block2{
  display: inline-block;
  width: calc(95% - 60px);
  font-weight: 500;
  font-size: 13px;
  color: #CECECE;
  vertical-align: top;
  padding-left: 4%;
  border-left: 1px solid #81898B;
}

.gc_store .pack_coin1{
  display: inline-block;
  width: calc(33% - 23px);
  margin-top: 20px;
  margin-right: 20px;
  background-color: #3A3F41;
  vertical-align: top;
  padding-left: 4%;
  border-radius: 12px;
  padding: 8px 5px;
  color: #FFF;
}

.gc_store .pack_coin2{
  display: inline-block;
  width: calc(33% - 23px);
  margin-top: 20px;
  margin-right: 20px;
  background-color: #252526;
  vertical-align: top;
  padding-left: 4%;
  border-radius: 12px;
  padding: 8px 5px;
  color: #FFF;
}

.gc_store .pack_coin3{
  display: inline-block;
  width: calc(33% - 23px);
  margin-top: 20px;
  background-color: #EBAD05;
  vertical-align: top;
  padding-left: 4%;
  border-radius: 12px;
  padding: 8px 5px;
  color: #000;
}

.gc_store h2{
  text-align: center;
  font-weight: 600;
  font-size: 5vw;
  width: 90%;
  padding-bottom: 8px;
  border-bottom: 1px solid #81898B;
  margin: 6px auto 10px auto;
}

.gc_store .pack_coin3 h2{
  border-bottom: 1px solid #000000 !important;
}

.gc_store .pack_coin1 .get_for{
  color: #CECECE;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.gc_store .pack_coin2 .get_for{
  color: #CECECE;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.gc_store .pack_coin3 .get_for{
  color: #000;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.gc_store .pack_coin1 .price{
  color: #FFF;
  font-weight: 600;
  font-size: 4vw;
  text-align: center;
}

.gc_store .pack_coin2 .price{
  color: #FFF;
  font-weight: 600;
  font-size: 4vw;
  text-align: center;
}

.gc_store .pack_coin3 .price{
  color: #000;
  font-weight: 600;
  font-size: 4vw;
  text-align: center;
}

.gc_store .gc_icon{
  width: 4vw;

  .discord {
    position: relative;
    right: auto;
    top: auto;
    margin: 30px auto 10px auto;
  }
}